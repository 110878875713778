.kycPersonalWrapper {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 45px;
}

@media only screen
and (max-device-width: 768px) {
    .kycPersonalWrapper {
        width: 100%;
    }
}