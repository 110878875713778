.myStepsContainer {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
}

.myStepsContainer :global(.ant-steps-item-title) {
    color: #333;
    font-weight: 600;
    transition: color 0.3s ease;
}

.title {
    margin: 0 64px 0 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    word-break: keep-all;
    margin-bottom: 40px;
}

@media only screen
and (max-device-width: 768px) {
    .myStepsContainer {
        width: 100%;
    }
}