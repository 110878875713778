.wrapper {
    /* background: #ffffff; */
    border-radius: 12px;
    /* width: 720px; */
    height: auto;
    padding: 24px 32px;
    box-sizing: border-box;
}

.page_title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2a2e37;
    margin-bottom: 32px;
}

/* .title { Should be removed!!!
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2a2e37;
    margin-bottom: 16px;
} */

.required_fields {
    font-weight: 600;
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

.input_block {
    width: 100%;
    position: relative;
}

.error_message {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ff6471;
    bottom: -20px;
    position: absolute;
}

.input_label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.input_label img {
    cursor: pointer;
}

.row {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_account {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
}

.radio_block {
    margin-top: 16px;
}

.line {
    margin: 32px 0;
    width: 100%;
    height: 1.5px;
    background: #f6f9ff;
}

.btn_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.button_back_wrapper {
    width: 180px;
}

.button_apply_wrapper {
    width: 180px;
}

.download_link {
    color: #89C0E9;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.modal_title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2a2e37;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
    white-space: pre-line;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    margin-top: 60px;
    width: 180px;
}

.error {
    border: 1.5px solid #ff6471 !important;
}

.checkboxes {
    display: flex;
    font-weight: 500;
    gap: 90px;
    justify-content: left;
}

.customCheckbox {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    border: 1.5px solid #eeefef;
    background: #fff;
}

.checkbox_wrapper {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.checkbox_block {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox_label {
    margin-left: 8px;
}

.sources_of_wealth_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media only screen and (max-device-width: 768px) {
    .wrapper {
        width: 100%;
        padding: 40px 16px;
    }

    .row {
        flex-direction: column;
    }

    .button_back_wrapper {
        width: 100%;
    }

    .button_apply_wrapper {
        width: 100%;
    }
}

.dateStyle {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    padding: 17px 16px;
    height: 55px;
    width: 100%;
    border: 1.5px solid #eeefef;
    border-radius: 8px;
}