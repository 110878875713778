.add_doc_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content_block {
    display: flex;
    gap: 24px;
}

.title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #2a2e37;
}

.doc_error {
    color: #ff6471;
}

.required {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ff6471;
}

.passport_spread_list {
    padding: 0;
    margin: 0 0 24px 0;
}

.passport_spread_list li {
    padding: 0;
    margin: 0 0 12px 18px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-color);
}

.text_color {
    color: #2a2e37;
}

.attention {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2a2e37;
}

.add_block_text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2a2e37;
    margin-bottom: 24px;
}

.line {
    margin: 32px 0;
    width: 100%;
    height: 1.5px;
    background: #f6f9ff;
}