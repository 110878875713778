.formContainer {
    padding: 0 16px;
    border-radius: 8px;
    background-color: #fff;
}

.input_block {
    width: 100%;
    position: relative;
    margin-bottom: 26px;
}

.input_label {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.error_message {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    color: #ff6471;
    position: absolute;
    bottom: -20px;
}

.buttonContainer {
    margin-top: 24px;
    text-align: right;
}

.saveButton {
    padding: 8px 16px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.checkbox_field {
    margin-top: 16px;
}

.isSingatory > * {
    font-weight: bold;
}

.input-error {
    border: 1px solid red;
}

.flex_label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.radio_block {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 8px;
}

.type_of_account {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #333333;
}