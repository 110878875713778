.principalContainer {
    padding-top: 24px;
}

.containerTitle {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #2a2e37;
}

.panelItem:only-child {
    border-radius: 8px !important;
}
.panelItem:first-child:not(:only-child) {
    border-radius: 8px 8px 0 0 !important;
}
.panelItem:last-child:not(:only-child) {
    border-radius: 0 0 8px 8px !important;
}

.panelItem {
    background-color: #113c62;
}

.panelItem > * {
    color: #fff !important;
}

.panelHeader {
    color: #fff;
}

.savedTag {
    color: #00ff08;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.8;
    float: right;
    text-transform: uppercase;
}

.actionRow {
    margin-top: 24px;
}

.trashIcon {
    float: right;
    cursor: pointer;
    margin-right: 10px;
}

.modal_title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2a2e37;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
    white-space: pre-line;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    margin-top: 60px;
    width: 180px;
}
