.confirmationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
    text-align: center;
}

.confirmationContent {
    max-width: 600px;
    margin: 0 auto;
}

.confirmationSpinner {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: #1890ff;
}

.confirmationTitle {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.confirmationDescription {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 1rem;
}

.confirmationNote {
    font-size: 0.9rem;
    color: #777;
}

.supportLink {
    color: #1890ff;
    text-decoration: none;
}

.supportLink:hover {
    text-decoration: underline;
}
