.attached_file_wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
}

.btn_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.hidden_input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.attached_file {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--secondary-color);
}

.rejected_doc_name {
    color: #ff6471;
}

.approved_doc_name {
    color: green;
}

.uploaded_doc_name {
    color: rgb(130, 130, 29);
}

.attached_file_name {
    color: var(--secondary-color);
}

.delete_doc {
    cursor: pointer;
}

@media only screen and (max-device-width: 768px) {
    .attached_file_wrapper {
        flex-direction: column;
        width: 100%;
    }

    .btn_block {
        width: 100%;
        flex-direction: column;
        gap: 16px;
    }
}